import chat from "../../assets/chat.svg";
import call from "../../assets/call.svg";
import { useEffect, useState } from "react";
import { authService } from "../../constant/authServices";
import { GPS_URL } from "../../constant/variable";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import arrowLeft from "../../assets/arrow-left.svg";
import imgPerson from "../../assets/img-person.png";

export default function DetailPersonRegu(props) {
  const {
    handleDetailPerson,
    styles,
    event,
    openChat,
    openConference,
    handleSeeDetail,
    setUserGroupList,
    checkRegu,
    selectedGroupsMember,
  } = props;
  let users = event?.users;
  let komandan = event?.komandan;
  let isLogin = authService.isLogin();
  isLogin = isLogin && JSON.parse(isLogin);

  useEffect(() => {
    if (users) setUserGroupList(users);
  }, [users]);
  // const getPersonList = async () => {
  //   let parse = JSON.parse(isLogin);
  //   try {
  //     const response = await axios.post(
  //       `${GPS_URL}getpersonilimage`,
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${parse.token}`,
  //         },
  //       }
  //     );
  //     setData(response.data?.message);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   // getPersonList();
  // }, []);
  return (
    <div className={styles.listPerson}>
      <div className={`${styles.btnBack} mb-4`} onClick={handleSeeDetail}>
        <span className="me-2">
          <img src={arrowLeft} />
        </span>
        Kembali
      </div>
      <></>
      {event
        ? komandan && (
            <div
              className="card mb-2 cursor-pointer"
              onClick={(e) => handleDetailPerson(e, komandan)}
            >
              <div className="d-flex justify-content-between">
                <div className={styles.titlePerson}>{komandan.name}</div>
                <div className={`d-flex gap-2 ${styles.btnWrapAction}`}>
                  <label className={styles.badgeKomandan}>Komandan</label>

                  <div
                    className={`${styles.signalIndicator} ${styles.good}`}
                  ></div>
                  {isLogin?.id !== komandan.id && (
                    <>
                      <div className="d-flex gap-2">
                        <div
                          className={styles.btnAction}
                          onClick={(e) =>
                            openChat(e, komandan?.id, "detail-regu")
                          }
                        >
                          <img src={chat} alt="chat" width={16} height={16} />
                        </div>
                        <div
                          className={styles.btnAction}
                          onClick={(e) =>
                            openConference(e, komandan, "detail-regu")
                          }
                        >
                          <img src={call} alt="call" width={16} height={16} />
                        </div>
                      </div>
                      <input
                        type="checkbox"
                        onChange={(e) => checkRegu(e)}
                        checked={selectedGroupsMember.includes(komandan.id)}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          )
        : ""}
      {event ? (
        users?.length > 0 ? (
          users?.map((item, index) => (
            <div
              className="card mb-2 cursor-pointer"
              onClick={(e) => handleDetailPerson(e, item)}
            >
              <div className="d-flex justify-content-between">
                <div className={styles.titlePerson}>{item.name}</div>
                <div className={`d-flex gap-2 ${styles.btnWrapAction}`}>
                  {}
                  <div
                    className={`${styles.signalIndicator} ${
                      item.signal > 3 && item.signal <= 5
                        ? styles.low
                        : item.signal > 5 && item.signal <= 7
                        ? styles.medium
                        : styles.good
                    }`}
                  ></div>
                  {isLogin?.id !== item.id && (
                    <>
                      <div className="d-flex gap-2">
                        <div
                          className={styles.btnAction}
                          onClick={(e) => openChat(e, item?.id, "detail-regu")}
                        >
                          <img src={chat} alt="chat" width={16} height={16} />
                        </div>
                        <div
                          className={styles.btnAction}
                          onClick={(e) =>
                            openConference(e, item, "detail-regu")
                          }
                        >
                          <img src={call} alt="call" width={16} height={16} />
                        </div>
                      </div>
                      <input
                        type="checkbox"
                        onChange={(e) => checkRegu(e, item.id, "detail-regu")}
                        checked={selectedGroupsMember.includes(item.id)}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">No data</div>
        )
      ) : (
        <>
          <Skeleton count={6} height={65} style={{ marginBottom: 6 }} />
        </>
      )}
    </div>
  );
}
