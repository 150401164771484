import axios from "axios";
import { authService } from "./authServices";
import { API_URL, AUTH_URL } from "./variable";
// import "https" from "https"

export const riyuzAPI = axios.create({
  baseURL: AUTH_URL,
  // httpsAgent: new https.Agent({
  //   rejectUnauthorized: true,
  // }),
});

// Add a request interceptor
riyuzAPI.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const isLogin = localStorage.getItem("cakrawala-auth");
    if (isLogin) {
      const _auth = JSON.parse(isLogin);
      config.headers.Authorization = localStorage.getItem("cakrawala-auth")
        ? "Bearer " + _auth.token
        : "";
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
riyuzAPI.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response?.data ? response?.data : response;
  },
  function (error) {
    if (error?.response?.status === 403) {
      const redirectTo = API_URL;
      authService.logoutNoRedirect();
      window.location.replace(redirectTo + "login");
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error.response);
  }
);
