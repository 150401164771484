import { Col, Image } from "react-bootstrap";

import background from "./../../assets/Home/bg.png";
import styles from "./../../styles/Login.module.scss";

export default function Background() {
  return (
    <Col lg={6} className={styles.background}>
      <Image src={background} alt="background" />
      <div className={styles.overlay}></div>
    </Col>
  );
}
