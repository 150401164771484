import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

export default function ReguItem(props) {
  const { handleSeeDetail, styles, data, selectedGroups, checkRegu } = props;

  return (
    <Row className={styles.listRegu}>
      {data ? (
        data?.length > 0 ? (
          data.map((_, index) => (
            <Col lg={6} key={index}>
              <div
                className="card _home"
                onClick={(e) => handleSeeDetail(e, _.id)}
              >
                <div className="d-flex justify-content-between">
                  <div className={styles.homeTitle}>{_.name}</div>
                  <div>
                    <input
                      type="checkbox"
                      onChange={(e) => checkRegu(e, _.id, "regu")}
                      checked={selectedGroups.includes(_.id)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <div className={styles.homeSubTitle}>Komandan</div>
                    <div className={styles.homeInfo}>
                      {_.komandan?.name ? _.komandan?.name : "-"}
                    </div>
                  </div>
                  <div>
                    <div className={styles.homeSubTitle}>Pasukan</div>
                    <div className={styles.homeInfo}>{_.users?.length}/17</div>
                  </div>
                </div>
              </div>
            </Col>
          ))
        ) : (
          ""
        )
      ) : (
        <Skeleton count={5} height={135} style={{ marginBottom: 10 }} />
      )}
    </Row>
  );
}
