import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./styles/global.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  useRoutes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import ProfileUser from "./pages/Profile";
import { authService } from "./constant/authServices";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const cookie = authService.isLogin();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(cookie);
    if (cookie) {
      setIsLoggedIn(true);
      navigate("/dashboard");
    } else {
      setIsLoggedIn(false);
      navigate("/login");
    }
  }, [cookie]);

  let routes = useRoutes([
    {
      path: "/",
      element: isLoggedIn ? (
        <Navigate to="/dashboard" />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/dashboard",
      element: <Home />,
    },
    {
      path: "/profile/:id",
      element: <ProfileUser />,
    },
  ]);
  return routes;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

reportWebVitals();
