export default function FloatingInfo(props) {
  const { styles, summary } = props;
  return (
    <div className={styles.positionSummary}>
      <div className={styles.infoSummary}>
        <div className="d-flex align-items-center gap-3">
          <div className={styles.leftBorder}></div>
          <div>
            <div className={styles.titleSummary}>Total Regu</div>
            <div className={styles.valueSummary}>{summary?.total_regu ? summary.total_regu : 0}</div>
          </div>
        </div>
        <div className="d-flex align-items-center gap-3">
          <div className={styles.leftBorder}></div>
          <div>
            <div className={styles.titleSummary}>Total Prajurit</div>
            <div className={styles.valueSummary}>{summary?.total_perajurit ? summary.total_perajurit : 0}</div>
          </div>
        </div>
        <div className="d-flex align-items-center gap-3">
          <div className={styles.leftBorder}></div>
          <div>
            <div className={styles.titleSummary}>Komunikasi ON</div>
            <div className={styles.valueSummary}>{summary?.total_komunikasi_on ? summary.total_komunikasi_on : 0}</div>
          </div>
        </div>
        <div className="d-flex align-items-center gap-3">
          <div className={styles.leftBorder}></div>
          <div>
            <div className={styles.titleSummary}>Komunikasi OFF</div>
            <div className="d-flex align-items-center gap-3">
              <div className={styles.valueSummary}>{summary?.total_komunikasi_off ? summary.total_komunikasi_off : 0}</div>
              <div className="mt-2">
                <select>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
