import { riyuzAPI } from "../constant/api";

export const AuthServices = {
  login(payload) {
    return riyuzAPI.post(`auth/login`, payload);
  },
  register(payload) {
    return riyuzAPI.post(`auth/register`, payload);
  },
  logout() {
    return riyuzAPI.post(`auth/logout`);
  },
  refresh() {
    return riyuzAPI.post(`auth/refresh`);
  },
  postMe(payload) {
    return riyuzAPI.post(`auth/me`, payload);
  },
  changePassword(payload) {
    return riyuzAPI.post(`auth/change-password`, payload);
  },
  getMe() {
    return riyuzAPI.get(`auth/me`);
  },
};
