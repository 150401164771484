import { Col, Row } from "react-bootstrap";
import styles from "./../../styles/Login.module.scss";
import Background from "../Auth/Background";
import Loading from "../common/Loading";
import { useEffect } from "react";
import { authService } from "../../constant/authServices";
import { useNavigate } from "react-router-dom";

export default function AuthLayout({ children, isLoading }) {
  return (
    <>
      {isLoading ? <Loading /> : ""}
      {/* <Loading /> */}
      <Row className={styles.boxAuth}>
        <Background />
        <Col lg={6} md={12} className={styles.formBox}>
          {children}
        </Col>
      </Row>
    </>
  );
}
