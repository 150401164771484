import { Button, Image } from "react-bootstrap";
import styles from "./../styles/Login.module.scss";
import email from "./../assets/Auth/email.svg";
import AuthLayout from "../components/Layout/AuthLayout";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("cakrawala-auth")) {
      navigate("/");
    }
  }, []);
  return (
    <AuthLayout>
      <div className={styles.circle}></div>
      <div className={styles.form}>
        <div className={styles.title}>Lupa Password</div>
        <div className={styles.text}>
          Masukan email kamu dan kami akan kirimkan link untuk mengganti
          password.
        </div>
        <form>
          <div className={styles.inputGroup}>
            <label className={styles.text}>Email</label>
            <div className={styles.inputBox}>
              <Image src={email} alt="email" />
              <input
                className={styles.text}
                type="email"
                placeholder="user@email.com"
              ></input>
            </div>
            <div className={styles.hook}>Kembali ke Login</div>
            <Button variant="primary" type="submit">
              Kirim
            </Button>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
}
