import search from "../../assets/search.svg";

export default function SearchData(props) {
  const { styles } = props;
  return (
    <div className={styles.inputWrapHome}>
      <img src={search} alt="search" />
      <input type="text" placeholder="Search" />
    </div>
  );
}
