import { Col, Modal, Row } from "react-bootstrap";
import DashboardLayout from "../components/Layout/DashboardLayout";
import { useEffect, useState, useRef } from "react";
import styles from "../styles/Home.module.scss";
import "leaflet/dist/leaflet.css";
import ReguItem from "../components/Home/ReguItem";
import DetailPersonRegu from "../components/Home/DetailPersonRegu";
import DetailPersonIndividu from "../components/Home/DetailPersonIndividu";
import FloatingInfo from "../components/Home/FloatingInfo";
import TabMenu from "../components/Home/TabMenu";
import SearchData from "../components/Home/SearchData";
import MapData from "../components/Home/MapData";
import SelectAllData from "../components/Home/SelectAllData";
import JitsiConference from "../components/Home/JitsiConference";
import { authService } from "../constant/authServices";
import { AUTH_URL, CHAT_URL, GPS_URL, USER_URL } from "../constant/variable";
import axios from "axios";
import ChatRoom from "../components/Home/ChatRoom";
import "react-loading-skeleton/dist/skeleton.css";
import AsyncSelect from "react-select/async";
import CommonModal from "../components/Home/CommonModal";
import Swal from "sweetalert2";
import { JitsiMeeting } from "@jitsi/react-sdk";
export default function Home() {
  let isLogin = authService.isLogin();
  isLogin = isLogin && JSON.parse(isLogin);
  let roleInfo = isLogin?.message?.user?.roles;
  const mapRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeMenu, setActiveMenu] = useState("chat");
  const [item, setItem] = useState(null);
  const [event, setEvent] = useState(null);
  const [meetURL, setMeetURL] = useState(null);
  const [name, setName] = useState(null);
  const [color, setColor] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllPerson, setSelectAllPerson] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedGroupsMember, setSelectedGroupsMember] = useState([]);
  const [isDetail, setIsDetail] = useState(false);
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [isOpenConference, setIsOpenConference] = useState(false);
  const [isDetailPerson, setIsDetailPerson] = useState(false);
  const [modal, setModal] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [idChat, setIdChat] = useState(null);
  const [idsConference, setIdsConference] = useState([]);
  const [contacts, setContacts] = useState(null);
  const [messages, setMessages] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [komandanSelected, setKomandanSelected] = useState([]);
  const [groupList, setGroupList] = useState(null);
  const [groupDetail, setGroupDetail] = useState(null);
  const [idGroup, setIdGroup] = useState(null);
  const [detailUser, setDetailUser] = useState(null);
  const [summary, setSummary] = useState(null);
  const [typeModal, setTypeModal] = useState("regu");
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [isCheckRegu, setIsCheckRegu] = useState(false);
  const [isCheckDetailRegu, setIsCheckDetailRegu] = useState(false);
  const [userGroupList, setUserGroupList] = useState(null);
  const [openFrom, setOpenFrom] = useState(null);
  const [isMinimize, setIsMinimize] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    role_id: "",
    group_id: "",
  });
  const [role, setRole] = useState([]);

  useEffect(() => {
    getEventList();
    const intervalId = setInterval(() => {
      getEventList();
    }, 60000); // 60000 ms = 1 minute
    getGroupList();
    getSummary();
    return () => clearInterval(intervalId); // Cleanup interval on component unmount

    // if (roleInfo.some((role) => role.slug !== "user")) {
    // }
  }, []);

  useEffect(() => {
    if (meetURL) {
      setIsMinimize(true);
    } else {
      setIsMinimize(false);
    }
  }, [meetURL]);

  useEffect(() => {
    if (modal) {
      getRole();
      fetchUsers();
    }
  }, [modal]);

  useEffect(() => {
    if (roleInfo.some((role) => role.slug == "user")) {
      if (isLogin?.message?.group?.length > 0) {
        setIdGroup(isLogin?.message?.group[0]?.id);
        setIsDetail(!isDetail);
      }
    }
  }, []);

  useEffect(() => {
    if (idGroup) getGroupList(idGroup);
  }, [idGroup]);

  useEffect(() => {
    if (idChat) getMessage(idChat);
  }, [idChat]);

  useEffect(() => {
    console.log(selectedGroupsMember);
  }, [selectedGroupsMember]);

  useEffect(() => {
    let intervalId;
    if (isOpenChat) {
      intervalId = setInterval(() => {
        getMessage(idChat);
      }, 5000);
    } else {
      setMessages(null);
    }
    return () => clearInterval(intervalId);
  }, [isOpenChat, idChat]);

  const handleSelectAll = (e, type) => {
    if (type === "regu") {
      setSelectAll(!selectAll);
      setIsCheckDetailRegu(false);
      setIsCheckRegu(!isCheckRegu);
      if (!selectAll) {
        const allGroupIds = groupList.map((group) => group.id);
        setSelectedGroups(allGroupIds);
      } else {
        setSelectedGroups([]);
      }
    } else {
      setSelectAllPerson(!selectAllPerson);
      setIsCheckRegu(false);
      setIsCheckDetailRegu(!isCheckDetailRegu);
      if (!selectAllPerson) {
        const allGroupIds = groupDetail?.users?.map((group) => group.id);
        setSelectedGroupsMember(allGroupIds);
      } else {
        setSelectedGroupsMember([]);
      }
    }
  };

  const changeMenu = (menu) => {
    setActiveMenu(menu);
  };

  const getRole = async () => {
    try {
      const response = await axios.get(`${AUTH_URL}roles`, {
        headers: {
          Authorization: `Bearer ${isLogin?.token}`,
        },
      });
      setRole(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${AUTH_URL}users?roleExcept[]=super-admin&roleExcept[]=admin&roleExcept[]=commandcenter`,
        {
          headers: {
            Authorization: `Bearer ${isLogin.token}`,
          },
        }
      );
      const options = response.data.data?.map((user) => ({
        value: user.id,
        label: user.name,
      }));
      setUserList(response.data.data);
      setUserOptions(options);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSeeDetail = (e, id) => {
    if (e.target.type === "checkbox") {
      return;
    }
    setSelectedGroupsMember([]);
    setIsDetail(!isDetail);
    setIdGroup(id);
    setGroupDetail(null);
  };

  const handleDetailPerson = (e, item) => {
    if (e.target.type === "checkbox") {
      return;
    }
    e.preventDefault();
    setIsDetailPerson(true);
    setItem(item);
    getDetailUser(item.id);
  };

  const handleChange = (e) => {
    const newData = { ...user };
    newData[e.target.id] = e.target.value;
    setUser(newData);
  };

  const getEventList = async () => {
    let params =
      isLogin.message.group?.length > 0
        ? `?group_id=${isLogin.message.group[0].id}`
        : "";
    console.log(isLogin);
    try {
      const response = await axios.post(
        `${GPS_URL}event${
          isLogin.message?.user?.roles.some(
            (role) =>
              role.slug === "user" || role.slug === "mobilecommandcenter"
          )
            ? params
            : ""
        }`,
        {},
        {
          headers: {
            Authorization: `Bearer ${isLogin.token}`,
          },
        }
      );
      setEvent(response.data?.message);
    } catch (error) {
      console.log(error);
    }
  };

  const getMessage = async (id) => {
    try {
      const response = await axios.post(
        `${CHAT_URL}fetchMessages`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${isLogin.token}`,
          },
        }
      );
      setMessages(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const openChat = (e, id) => {
    e.stopPropagation();
    setIsOpenChat(!isOpenChat);
    if (id) {
      setIdChat(id);
    } else {
      setIdChat(null);
    }
  };

  const openConference = (e, id, openFrom) => {
    e.stopPropagation();
    let arr = [...idsConference];
    arr.push(id);
    setActiveMenu("conference");
    setIsOpenConference(!isOpenConference);
    setIdsConference(arr);
    setOpenFrom(openFrom);
  };

  const handleAddData = (type) => {
    setTypeModal(type);
    setModal(true);
  };

  const createRegu = async () => {
    let tmp = selectedOptions.map((option) => option.value);
    const result = tmp.some((id) => {
      const user = userList.find((user) => user.id === id);
      return user && user.groups.length > 0;
    });

    if (result) {
      setModal(false);
      setIsConfirmation(true);
    } else {
      setIsConfirmation(false);
      createDataRegu();
    }
  };

  const createDataRegu = async () => {
    let payload = {
      name: name,
      komandan_user_id: komandanSelected.value,
      color: color,
    };
    try {
      const response = await axios.post(`${AUTH_URL}groups`, payload, {
        headers: {
          Authorization: `Bearer ${isLogin.token}`,
        },
      });
      if (response.data?.success) {
        let idGroup = response.data?.group?.id;
        if (selectedOptions.length > 0) {
          assignRegu(idGroup, "multiple", "");
        } else {
          getGroupList();
          Swal.fire({
            title: "Success",
            text: "Data created successfully",
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createUser = async () => {
    let payload = {
      name: user.name,
      email: user.email,
      password: user.password,
    };
    try {
      const response = await axios.post(`${AUTH_URL}users`, payload, {
        headers: {
          Authorization: `Bearer ${isLogin.token}`,
        },
      });
      if (response.status === 201) {
        let idUser = response.data.id;
        assignRegu(user.group_id, "single", idUser);
        assignRole(idUser);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const assignRole = async (id) => {
    let payload = {
      role_id: user.role_id,
    };
    try {
      const response = await axios.post(
        `${AUTH_URL}users/${id}/roles`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${isLogin.token}`,
          },
        }
      );
      if (response) {
        fetchUsers();
        setModal(false);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const assignRegu = async (id, type, idUser) => {
    let payload = {
      user_id:
        type === "single"
          ? `${idUser}`
          : selectedOptions.map((option) => option.value).join(","),
    };
    try {
      const response = await axios.post(
        `${AUTH_URL}groups/${id}/users`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${isLogin.token}`,
          },
        }
      );
      if (response.data.success) {
        setModal(false);
        getGroupList(idGroup);
        Swal.fire({
          title: "Success",
          text: "Data created successfully",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Error creating data",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
      console.log(error);
    }
  };

  const loadOptions = async (inputValue) => {
    try {
      const response = await axios.get(
        `${AUTH_URL}users?roleExcept[]=super-admin&roleExcept[]=admin&roleExcept[]=commandcenter`,
        {
          headers: {
            Authorization: `Bearer ${isLogin.token}`,
          },
          params: {
            keyword: inputValue,
          },
        }
      );
      return response.data.data?.map((user) => ({
        value: user.id,
        label: user.name,
      }));
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getGroupList = async (id) => {
    try {
      const response = await axios.get(
        `${AUTH_URL}groups${id ? `/${id}` : ""}`,
        {
          headers: {
            Authorization: `Bearer ${isLogin.token}`,
          },
        }
      );
      if (response.data?.success) {
        if (id) {
          setGroupDetail(response.data?.group);
        } else {
          setGroupList(response.data?.groups);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDetailUser = async (id) => {
    try {
      const response = await axios.get(`${AUTH_URL}user-details/${id}`, {
        headers: {
          Authorization: `Bearer ${isLogin.token}`,
        },
      });
      if (response.data?.success) {
        let res = response.data.data;
        setDetailUser(res);
        setLatitude(res?.devices[0]?.latest_tracking?.latitude);
        setLongitude(res?.devices[0]?.latest_tracking?.longitude);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(isMinimize && meetURL !== null);
  }, [isMinimize, meetURL]);

  const getSummary = async () => {
    try {
      const response = await axios.get(`${AUTH_URL}summary`, {
        headers: {
          Authorization: `Bearer ${isLogin.token}`,
        },
      });
      setSummary(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBackToPrev = () => {
    setActiveMenu("chat");
    setIsMinimize(true);
    if (openFrom === "detail-regu") {
      setIsDetailPerson(false);
      setIsDetail(true);
    } else if (openFrom === "detail-individu") {
      setIsDetailPerson(true);
      setIsDetail(false);
    }
  };
  const checkRegu = (e, id, type) => {
    if (type === "detail-regu") {
      if (e.target.checked) {
        setSelectedGroupsMember([...selectedGroupsMember, id]);
        setIdsConference([...idsConference, id]);
      } else {
        setIdsConference(idsConference.filter((item) => item !== id));
        setSelectedGroupsMember(
          selectedGroupsMember.filter((item) => item !== id)
        );
      }
    }
    if (type === "regu") {
      if (e.target.checked) {
        setSelectedGroups([...selectedGroups, id]);
      } else {
        setSelectedGroups(selectedGroups.filter((item) => item !== id));
      }
    }
  };

  const assignUserToRegu = () => {
    assignRegu(idGroup, "multiple", "");
  };

  const startConference = () => {
    setActiveMenu("conference");
    setIsOpenConference(!isOpenConference);
  };

  return (
    <DashboardLayout isLoading={isLoading}>
      <Row className="mb-5 mb-md-0 px-3">
        <Col md={12} lg={8}>
          <div className="position-relative mb-4">
            <MapData
              event={event}
              styles={styles}
              latitude={latitude}
              longitude={longitude}
              isDetailPerson={isDetailPerson}
              mapRef={mapRef}
            />
            <FloatingInfo styles={styles} summary={summary} />
          </div>
        </Col>
        <Col md={12} lg={4} className="position-relative">
          <TabMenu
            styles={styles}
            changeMenu={changeMenu}
            activeMenu={activeMenu}
            idsConference={idsConference}
          />
          {activeMenu === "chat" && (
            <>
              <div className="d-flex justify-content-between gap-3">
                <SearchData styles={styles} />
                {(roleInfo.some((role) => role.slug === "commandcenter") ||
                  roleInfo.some((role) => role.slug === "super-admin") ||
                  roleInfo.some((role) => role.slug === "admin")) && (
                  <>
                    {isDetail ? (
                      <button
                        className={styles.btnAddRegu}
                        onClick={() => handleAddData("assign_user")}
                      >
                        + Assign User
                      </button>
                    ) : (
                      <>
                        <button
                          className={styles.btnAddRegu}
                          onClick={() => handleAddData("regu")}
                        >
                          + Regu
                        </button>
                        <button
                          className={styles.btnAddRegu_outline}
                          onClick={() => handleAddData("user")}
                        >
                          + User
                        </button>
                      </>
                    )}
                    {selectedGroupsMember?.length > 0 && (
                      <button
                        className={styles.btnAddRegu_outline}
                        onClick={startConference}
                      >
                        Start Group Conference
                      </button>
                    )}
                  </>
                )}
              </div>

              {roleInfo.some((role) => role.slug === "user") && (
                <>
                  {!isDetailPerson && !isOpenChat && (
                    <DetailPersonRegu
                      handleDetailPerson={handleDetailPerson}
                      styles={styles}
                      openChat={openChat}
                      openConference={openConference}
                      event={groupDetail}
                      handleSeeDetail={handleSeeDetail}
                      setUserGroupList={setUserGroupList}
                      checkRegu={checkRegu}
                    />
                  )}

                  {isDetailPerson && isOpenChat && (
                    <ChatRoom
                      styles={styles}
                      openChat={openChat}
                      id={idChat}
                      isOpenChat={isOpenChat}
                      messages={messages}
                      getMessage={getMessage}
                    />
                  )}
                  {isDetailPerson && !isOpenChat && (
                    <DetailPersonIndividu
                      openChat={openChat}
                      openConference={openConference}
                      setIsDetailPerson={setIsDetailPerson}
                      styles={styles}
                      item={detailUser}
                    />
                  )}
                </>
              )}
              {roleInfo.some((role) => role.slug !== "user") && (
                <>
                  {isDetailPerson && isOpenChat && (
                    <ChatRoom
                      styles={styles}
                      openChat={openChat}
                      id={idChat}
                      isOpenChat={isOpenChat}
                      messages={messages}
                      getMessage={getMessage}
                    />
                  )}
                  {isDetailPerson && !isOpenChat && (
                    <DetailPersonIndividu
                      openChat={openChat}
                      openConference={openConference}
                      setIsDetailPerson={setIsDetailPerson}
                      styles={styles}
                      item={detailUser}
                    />
                  )}

                  {!isDetailPerson && isDetail && (
                    <SelectAllData
                      styles={styles}
                      title="anggota"
                      selectAll={selectAllPerson}
                      handleSelectAll={handleSelectAll}
                    />
                  )}

                  {!isDetailPerson && isDetail && isOpenChat && (
                    <ChatRoom
                      styles={styles}
                      openChat={openChat}
                      id={idChat}
                      isOpenChat={isOpenChat}
                      messages={messages}
                      getMessage={getMessage}
                    />
                  )}

                  {!isDetailPerson && isDetail && !isOpenChat && (
                    <DetailPersonRegu
                      handleDetailPerson={handleDetailPerson}
                      styles={styles}
                      openChat={openChat}
                      openConference={openConference}
                      event={groupDetail}
                      handleSeeDetail={handleSeeDetail}
                      setUserGroupList={setUserGroupList}
                      selectedGroupsMember={selectedGroupsMember}
                      checkRegu={checkRegu}
                    />
                  )}

                  {!isDetailPerson && !isDetail && (
                    <>
                      <SelectAllData
                        styles={styles}
                        title="regu"
                        selectAll={selectAll}
                        handleSelectAll={handleSelectAll}
                      />
                      <ReguItem
                        handleSeeDetail={handleSeeDetail}
                        styles={styles}
                        data={groupList}
                        selectedGroups={selectedGroups}
                        checkRegu={checkRegu}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}

          {isMinimize && meetURL !== null && (
            <div
              className="position-absolute"
              style={{ bottom: "16px", right: "16px" }}
            >
              <div
                onClick={() => {
                  setIsMinimize(!isMinimize);
                  setActiveMenu("conference");
                }}
                className={`${styles.btnFull} position-absolute`}
              >
                See Full
              </div>
              <JitsiConference
                styles={styles}
                activeMenu={activeMenu}
                idsConference={idsConference}
                handleBackToPrev={handleBackToPrev}
                meetURL={meetURL}
                setMeetURL={setMeetURL}
                isMinimize={isMinimize}
                setIsMinimize={setIsMinimize}
              />
            </div>
          )}

          {activeMenu === "conference" && (
            <JitsiConference
              styles={styles}
              activeMenu={activeMenu}
              idsConference={idsConference}
              handleBackToPrev={handleBackToPrev}
              meetURL={meetURL}
              setMeetURL={setMeetURL}
              isMinimize={isMinimize}
              setIsMinimize={setIsMinimize}

            />
          )}
        </Col>
      </Row>

      <CommonModal
        title="Salah satu anggota sudah ada dalam regu lain!"
        show={isConfirmation}
        onHide={() => setIsConfirmation(false)}
      >
        <div className="">
          <p className="mb-4">
            Jika dilanjutkan, anggota akan dipindahkan ke regu baru. Apakah anda
            ingin melanjutkan?
          </p>
          <div className="w-100 text-end">
            <button
              onClick={() => setIsConfirmation(false)}
              className={`${styles.btnAddRegu_outline} mb-0 me-3`}
            >
              Tidak
            </button>
            <button
              onClick={() => {
                setIsConfirmation(false);
                createDataRegu();
              }}
              className={`${styles.btnAddRegu} mb-0`}
            >
              Ya
            </button>
          </div>
        </div>
      </CommonModal>

      <CommonModal
        title={
          typeModal === "regu"
            ? "Tambah Regu"
            : typeModal === "assign_user"
            ? "Assign User"
            : "Tambah User"
        }
        show={modal}
        onHide={() => setModal(false)}
      >
        {typeModal === "regu" && (
          <>
            <div className="form-group d-flex justify-content-between mb-3">
              <label htmlFor="color" className="mb-2 w-100">
                Warna Regu
              </label>
              <input
                type="color"
                className="form-control"
                id="color"
                onChange={(e) => setColor(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="regu" className="mb-2">
                Nama Regu
              </label>
              <input
                type="text"
                className="form-control"
                id="regu"
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="assign" className="mb-2">
                Assign Komandan Regu
              </label>
              <AsyncSelect
                id="assign"
                loadOptions={loadOptions}
                defaultOptions={userOptions}
                onChange={(selectedOptions) => {
                  setKomandanSelected(selectedOptions);
                }}
              />
            </div>
            <div className="form-group mb-5">
              <label htmlFor="assign" className="mb-2">
                Assign Anggota Regu
              </label>
              <AsyncSelect
                id="assign"
                loadOptions={loadOptions}
                defaultOptions={userOptions}
                isMulti
                onChange={(selectedOptions) => {
                  setSelectedOptions(selectedOptions);
                }}
              />
            </div>
            <div className="d-flex justify-content-end">
              <button
                onClick={createRegu}
                className={`${styles.btnAddRegu} mb-0`}
              >
                Tambah Regu
              </button>
            </div>
          </>
        )}
        {typeModal === "user" && (
          <>
            <div className="form-group mb-3">
              <label htmlFor="name" className="mb-2">
                Nama
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                onChange={handleChange}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="email" className="mb-2">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                onChange={handleChange}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="email" className="mb-2">
                Role
              </label>
              <select
                className="form-control"
                id="role_id"
                onChange={handleChange}
              >
                <option value="" hidden>
                  Pilih Role
                </option>
                {role.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="assign" className="mb-2">
                Assign Group
              </label>
              <select
                className="form-control"
                id="group_id"
                onChange={handleChange}
              >
                <option value="" hidden>
                  Pilih Group
                </option>
                {groupList.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group mb-5">
              <label htmlFor="password" className="mb-2">
                Password
              </label>
              <input
                type="text"
                className="form-control"
                id="password"
                onChange={handleChange}
              />
            </div>
            <div className="d-flex justify-content-end">
              <button
                onClick={createUser}
                className={`${styles.btnAddRegu} mb-0`}
              >
                Tambah User
              </button>
            </div>
          </>
        )}
        {typeModal === "assign_user" && (
          <>
            <div className="form-group mb-5">
              <label htmlFor="assign" className="mb-2">
                Assign Anggota Regu
              </label>
              <AsyncSelect
                id="assign"
                loadOptions={loadOptions}
                defaultOptions={userOptions}
                isMulti
                onChange={(selectedOptions) => {
                  setSelectedOptions(selectedOptions);
                }}
              />
            </div>
            <div className="d-flex justify-content-end">
              <button
                onClick={assignUserToRegu}
                className={`${styles.btnAddRegu} mb-0`}
              >
                Assign
              </button>
            </div>
          </>
        )}
      </CommonModal>
    </DashboardLayout>
  );
}
