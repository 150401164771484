export default function SelectAllData(props) {
  const { styles, title, selectAll, handleSelectAll } = props;

  return (
    <div className="d-flex gap-2 align-items-center justify-content-between mb-4">
      <div className={styles.greyLabel}>Pilih semua {title}</div>
      <input
        type="checkbox"
        onChange={(e) => handleSelectAll(e, title)}
        checked={selectAll}
      />
    </div>
  );
}
