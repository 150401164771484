export const isMobileCheck = () => {
  let dimension = window.innerWidth;
  let isMobile = false;
  if (dimension <= 768) {
    isMobile = true;
  } else {
    isMobile = false;
  }
  return isMobile;
};
