// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_formLogin__Fe7Ih input {
  padding: 12px !important;
  border-radius: 4px !important;
  background-color: #f5f5f5 !important;
}
.Login_formLogin__Fe7Ih input[type=password] {
  padding-right: 6rem !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/Login.module.scss"],"names":[],"mappings":"AACE;EACE,wBAAA;EACA,6BAAA;EACA,oCAAA;AAAJ;AAEE;EACE,8BAAA;AAAJ","sourcesContent":[".formLogin {\n  input {\n    padding: 12px !important;\n    border-radius: 4px !important;\n    background-color: #f5f5f5 !important;\n  }\n  input[type=\"password\"] {\n    padding-right: 6rem !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formLogin": `Login_formLogin__Fe7Ih`
};
export default ___CSS_LOADER_EXPORT___;
