import { useEffect, useState } from "react";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { authService } from "../../constant/authServices";
import arrowLeft from "../../assets/arrow-left.svg";
import { CONFERENCE_URL } from "../../constant/variable";
import axios from "axios";

export default function JitsiConference(props) {
  const {
    activeMenu,
    idsConference,
    styles,
    handleBackToPrev,
    meetURL,
    setMeetURL,
    isMinimize,
    setIsMinimize,
  } = props;
  const isLogin = authService.isLogin();
  let parse = JSON.parse(isLogin);
  console.log(parse);
  const password = process.env.REACT_APP_JITSI_PASSWORD;
  const [startConference, setStartConference] = useState(null);
  const [isOnLobby, setIsOnLobby] = useState(false);

  const [isJitsiActive, setIsJitsiActive] = useState(false);
  useEffect(() => {
    if (activeMenu === "conference") {
      setIsJitsiActive(true);
      setIsMinimize(false);
    } else {
      setIsJitsiActive(false);
    }
  }, [activeMenu]);

  useEffect(() => {
    if (isMinimize && meetURL !== null) {
      setIsJitsiActive(true);
    }
  }, [isMinimize, meetURL]);

  useEffect(() => {
    console.log(isMinimize, isJitsiActive, meetURL);
  }, [isMinimize, isJitsiActive, meetURL]);

  useEffect(() => {
    if (meetURL) {
      joinConference();
    }
    console.log(meetURL, "<=== meetURL");
  }, [meetURL]);

  useEffect(() => {
    console.log(idsConference, "<=== idsConference");
    if (idsConference.length > 0 && meetURL === null) {
      startConferenceData();
    }
  }, [idsConference, meetURL, isMinimize]);

  const startConferenceData = async () => {
    let payload = {
      users: idsConference.map((item) => item.id || item),
    };
    try {
      const response = await axios.post(
        `${CONFERENCE_URL}startconference`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${parse?.token}`,
          },
        }
      );
      if (response) {
        let conference = response.data?.message.url_room;
        setMeetURL(conference);
      } else {
        setMeetURL("meet.pernika.net/meshmeet");
      }
      console.log(response, "<=== response");
    } catch (error) {
      console.log(error);
      setMeetURL("meet.pernika.net/meshmeet");
    }
  };

  const joinConference = async () => {
    let payload = {
      user: parse.id,
    };
    try {
      const response = await axios.post(`${CONFERENCE_URL}joinroom`, payload, {
        headers: {
          Authorization: `Bearer ${parse?.token}`,
        },
      });
      if (response) {
        setIsOnLobby(true);
        console.log(response, "<=== response");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const turnOnMic = async () => {
    let payload = {
      users: [parse.id],
    };
    console.log(isLogin?.token);
    try {
      const response = await axios.post(`${CONFERENCE_URL}onmic`, payload, {
        headers: {
          Authorization: `Bearer ${parse?.token}`,
        },
      });
      if (response) {
        console.log(response, "<=== response");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const turnOnCamera = async () => {
    let payload = {
      users: [parse.id],
    };
    console.log(isLogin?.token);
    try {
      const response = await axios.post(`${CONFERENCE_URL}oncamera`, payload, {
        headers: {
          Authorization: `Bearer ${parse?.token}`,
        },
      });
      if (response) {
        console.log(response, "<=== response");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const turnOffMic = async () => {
    let payload = {
      users: [parse.id],
    };
    try {
      const response = await axios.post(`${CONFERENCE_URL}offcamera`, payload, {
        headers: {
          Authorization: `Bearer ${parse?.token}`,
        },
      });
      if (response) {
        console.log(response, "<=== response");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const turnOffCamera = async () => {
    let payload = {
      users: [parse.id],
    };
    try {
      const response = await axios.post(`${CONFERENCE_URL}offmic`, payload, {
        headers: {
          Authorization: `Bearer ${parse?.token}`,
        },
      });
      if (response) {
        console.log(response, "<=== response");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    isJitsiActive && (
      <>
        {!isMinimize && (
          <div className={`${styles.btnBack} mb-4`} onClick={handleBackToPrev}>
            <span className="me-2">
              <img src={arrowLeft} />
            </span>
            Kembali
          </div>
        )}
        {meetURL && (
          <JitsiMeeting
            domain="meet.pernika.net"
            roomName={meetURL.split("/").pop()} // Extract room name from meetURL
            configOverwrite={{
              startWithAudioMuted: false,
              disableModeratorIndicator: false,
              startScreenSharing: true,
              enableEmailInStats: false,
              recordings: {
                showPrejoinWarning: false,
              },
              // securityUi: {
              //   hideLobbyButton: true,
              //   disableLobbyPassword: true,
              // },
              prejoinConfig: {
                enabled: false,
              },
            }}
            interfaceConfigOverwrite={{
              DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
            }}
            userInfo={{
              displayName: parse.message?.user?.name,
            }}
            onApiReady={(externalApi) => {
              console.log("Config Overwrite:", {
                startWithAudioMuted: false,
                disableModeratorIndicator: false,
                startScreenSharing: true,
                enableEmailInStats: false,
                prejoinPageEnabled: false,
              });
              // Auto-join the conference
              // externalApi.executeCommand('displayName', parse.message?.user?.name);
              // externalApi.executeCommand('password', password);

              // Add event listeners for audio and video mute status changes
              externalApi.addEventListener(
                "audioMuteStatusChanged",
                (payload) => {
                  payload.muted ? turnOffMic() : turnOnMic();
                  console.log(`Microphone is ${payload.muted ? "off" : "on"}`);
                }
              );

              externalApi.addEventListener(
                "videoMuteStatusChanged",
                (payload) => {
                  payload.muted ? turnOffCamera() : turnOnCamera();
                  console.log(`Camera is ${payload.muted ? "off" : "on"}`);
                }
              );

              // Add event listener for when the user joins the conference
              externalApi.addEventListener("videoConferenceJoined", () => {
                console.log("User has joined the conference");
                joinConference();
              });

              // Ensure externalApi is correctly initialized
              console.log("externalApi initialized:", externalApi);

              // Add a check to see if the event listener is added
              externalApi.addEventListener("videoConferenceJoined", () => {
                console.log("Event listener for videoConferenceJoined added");
              });

              externalApi.addEventListener("videoConferenceLeft", () => {
                console.log("User has left the conference");
                // Perform any additional actions here
                setMeetURL(null);
                setIsMinimize(false);
                setIsJitsiActive(false); // Example action: set Jitsi active state to false
              });
            }}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.height = isMinimize
                ? "calc(60vh - 15rem)"
                : "calc(100vh - 15rem)";
            }}
          />
        )}
      </>
    )
  );
}
