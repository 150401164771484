import { useState } from "react";
import logo from "../../assets/logo-cakrawala.png";
import logout from "../../assets/logout.svg";
import { Container } from "react-bootstrap";
import { authService } from "../../constant/authServices";
import { useNavigate } from "react-router-dom";

export default function DashboardLayout({ children, isLoading }) {
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    document.cookie =
      "cakrawala_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.href = "/login";
    authService.logout(navigate);
  };
  return (
    <div className={`app ${toggled ? "toggled" : ""}`}>
      <div className="p-3 w-100  sticky-header">
        <header
          className={`d-flex justify-content-between align-items-center `}
        >
          <div className="d-flex align-items-center gap-2">
            <img src={logo} width={56} />
            <div>
              <b>Cakrawala Datalink</b>
            </div>
          </div>
          <div onClick={() => handleLogout()} className="cursor-pointer">
            <img src={logout} width={24} />
          </div>
        </header>
      </div>
      <div>{children}</div>
    </div>
  );
}
