import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Spinner,
  Toast,
} from "react-bootstrap";
import styles from "./../styles/Login.module.scss";
import email from "./../assets/Auth/email.svg";
import eye from "./../assets/Auth/eye.svg";
import logo from "./../assets/logo-cakrawala.png";
import password from "./../assets/Auth/password.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { AUTH_URL } from "../constant/variable";
import axios, { Axios } from "axios";
import { authService } from "../constant/authServices";

export default function Login() {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let response = await axios.post(AUTH_URL + "login", data);
      authService.setLogin(response.data);
      navigate("/dashboard");
      setIsLoading(false);
    } catch (error) {
      setShowToast(true);
      setIsLoading(false);
    }
  };

  const changeTypePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div style={{ height: "100vh" }}>
        <Container className="h-100">
          <Row className="justify-content-center h-100 align-items-center">
            <Col lg={4} md={6} sm={12}>
              <div className="card _login">
                <div className="d-flex flex-column align-items-center">
                  <img src={logo} alt="logo" className="mb-2" />
                  <div className="w-100 text-center mb-1">
                    <b>Login</b>
                  </div>
                  <p
                    className="w-100 text-center mb-5"
                    style={{ fontSize: "14px" }}
                  >
                    Login to your account
                  </p>
                </div>
                <form className={styles.formLogin} onSubmit={handleSubmit}>
                  <div className="form-group mb-3">
                    <label className="mb-2">Email</label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group mb-5">
                    <label className="mb-2">Password</label>
                    <div className="position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        style={{ paddingRight: "6rem " }}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <button
                        style={{
                          right: "16px",
                          top: "0.65rem",
                          fontSize: "12px",
                        }}
                        className="position-absolute btn btn-sm btn-primary px-3"
                        onClick={changeTypePassword}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </button>
                    </div>
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100 mb-3 d-flex align-items-center justify-content-center gap-2"
                    style={{ fontSize: "14px", padding: "14px 12px" }}
                  >
                    {isLoading ? <Spinner size="sm" /> : ""}
                    <b>Login</b>
                  </Button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Toast
        bg="danger"
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
      >
        <Toast.Body>
          <b>Login Gagal!</b>
        </Toast.Body>
      </Toast>
    </>
  );
}
