import arrowLeft from "../../assets/arrow-left.svg";
import arrowRight from "../../assets/arrow-right.svg";
import chatFill from "../../assets/chat-fill.svg";
import callFill from "../../assets/call-fill.svg";
import imgPerson from "../../assets/img-person.png";
import { Button, Col, Row } from "react-bootstrap";
import { authService } from "../../constant/authServices";
import Skeleton from "react-loading-skeleton";

export default function DetailPersonIndividu(props) {
  const { setIsDetailPerson, styles, item, openChat, openConference } = props;
  // const isLogin = authService.isLogin();
  return (
    <div className="card _detail-person">
      <div className="d-flex justify-content-between flex-column flex-lg-row ">
        <div
          className={styles.btnBack}
          onClick={() => setIsDetailPerson(false)}
        >
          <span className="me-2">
            <img src={arrowLeft} />
          </span>
          Kembali
        </div>
        <div className="d-flex gap-2">
          <div className={styles.btnOutline}>
            <span className="me-2">
              <img src={arrowLeft} />
            </span>
            Previous
          </div>
          <div className={styles.btnOutline}>
            Next
            <span className="ms-2">
              <img src={arrowRight} />
            </span>
          </div>
        </div>
      </div>
      {item ? (
        <>
          <div className="d-flex justify-content-between mt-3 mb-4">
            <div className="">
              <div className={styles.namePerson}>{item.name}</div>
              <div className={styles.jabatanPerson}>Letnan</div>
            </div>
            <div className="d-flex gap-2 align-items-center ">
              <Button
                className={styles.btnOutlineDetail}
                onClick={(e) => openChat(e, item)}
              >
                <img src={chatFill} width={24} height={24} />
              </Button>
              <Button
                className={styles.btnOutlineDetail}
                onClick={(e) => openConference(e, item, "detail-individu")}
              >
                <img src={callFill} width={24} height={24} />
              </Button>
            </div>
          </div>
          <div className={`${styles.infoPerson} mb-3`}>
            <div
              className={`${styles.imgWrap} d-flex justify-content-center align-items-center`}
            >
              {item.photo ? (
                <img src={item.photo} alt="person" />
              ) : (
                <b>Tidak Ada Foto</b>
              )}
            </div>
            <div className={styles.infoWrap}>
              <div className={styles.infoDetailPerson}>
                <b>Misi:</b>
              </div>
              <div className={styles.infoDetailPerson}>
                <b>Regu: </b>
                {item.groups?.map((item) => item.name).join(", ")}
              </div>
              <div className={styles.infoDetailPerson}>
                <b>Latitude: </b>
                {item.devices?.length > 0
                  ? item.devices[0].latest_tracking?.latitude
                  : "-"}
              </div>
              <div className={styles.infoDetailPerson}>
                <b>Longitude: </b>
                {item.devices?.length > 0
                  ? item.devices[0].latest_tracking?.longitude
                  : "-"}
              </div>
              <div className={styles.infoDetailPerson}>
                <b>Heading: </b>
                {item.devices?.length > 0
                  ? item.devices[0].latest_tracking?.heading
                  : "-"}
              </div>
              <div className={styles.infoDetailPerson}>
                <b>Speed: </b>
                {item.devices?.length > 0
                  ? item.devices[0].latest_tracking?.velocity
                  : "-"}
                KT
              </div>
              <div className={styles.infoDetailPerson}>
                <b>Time Stamp: </b>{" "}
                {item.devices?.length > 0
                  ? item.devices[0].latest_tracking?.timestamp_gps
                  : "-"}
              </div>
              <div className={styles.infoDetailPerson}>
                <b>IMEI:</b>{" "}
                {item.devices?.length > 0
                  ? item.devices[0].latest_tracking?.device_imei
                  : "-"}
              </div>
              <div className={styles.infoDetailPerson}>
                <b>Device Name: </b>{" "}
                {item.devices?.length > 0 ? item.devices[0]?.device_name : "-"}
              </div>
              <div className={styles.infoDetailPerson}>
                <b>Device IP: </b>{" "}
                {item.devices?.length > 0 ? item.devices[0]?.device_ip : "-"}
              </div>
              <div className={styles.infoDetailPerson}>
                <b>Device Type: </b>{" "}
                {item.devices?.length > 0 ? item.devices[0]?.device_type : "-"}
              </div>
            </div>
          </div>
          <Row className="d-flex">
            {item.devices?.length > 0 &&
              item.devices[0]?.three_latest_media_files?.map((_, idx) => {
                return (
                  <Col xs={4} lg={4}>
                    <img
                      className="w-inherit"
                      src={`https://datalink-gps.pernika.net/uploads/${_.file_path}`}
                      alt="person"
                    />
                  </Col>
                );
              })}
          </Row>
        </>
      ) : (
        <>
          <Skeleton height={90} style={{ marginBottom: 6 }} />
          <Skeleton height={310} style={{ marginBottom: 6 }} />
          <Skeleton height={200} />
        </>
      )}
    </div>
  );
}
