import { Button } from "react-bootstrap";
import chat from "../../assets/chat.svg";
import conference from "../../assets/video.svg";
export default function TabMenu(props) {
  const { styles, changeMenu, activeMenu, idsConference } = props;
  return (
    <div className="d-flex gap-3 mb-4 justify-content-between">
      <Button
        onClick={() => changeMenu("chat")}
        className={`btn-primary ${
          activeMenu === "chat" ? "btn-active" : ""
        } w-50 ${styles.homeBtn}`}
      >
        <span className="me-3">
          <img src={chat} alt="chat" />
        </span>
        Chat
      </Button>
      <Button
        onClick={() =>
          idsConference.length > 0 ? changeMenu("conference") : false
        }
        className={`btn-primary w-50 ${
          activeMenu === "conference" ? "btn-active" : ""
        } ${styles.homeBtn}`}
      >
        <span className="me-3">
          <img src={conference} alt="conference" width={24} height={24} />
        </span>
        Conference
      </Button>
    </div>
  );
}
