import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  FeatureGroup,
  MapConsumer,
  Circle,
  Rectangle,
  Polygon,
  Polyline,
  CircleMarker,
} from "react-leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import { GPS_URL, BREADCRUMB_URL } from "../../constant/variable";
import { authService } from "../../constant/authServices";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import L, { divIcon, Control, DivIcon } from "leaflet";
import marker from "../../assets/marker.svg";
import markerBlue from "../../assets/marker-blue.svg";
import "leaflet-draw";
import "leaflet-rotatedmarker";
import { EditControl } from "react-leaflet-draw";
import {
  createPathComponent,
  createControlComponent,
  useLeafletContext,
} from "@react-leaflet/core";
import { Modal } from "react-bootstrap";
import CommonModal from "./CommonModal";
import AsyncSelect from "react-select/async";
import moment from "moment";

function FlyToLocation({ latitude, longitude, isDetailPerson, markerRefs }) {
  const map = useMap();

  useEffect(() => {
    if (isDetailPerson && latitude && longitude) {
      map.flyTo([latitude, longitude], 8);
      openPopupForLocation(latitude, longitude, markerRefs);

      // if (selectedMarker) {
      //   console.log("Selected Marker Found:", selectedMarker);
      //   selectedMarker.openPopup();
      // } else {
      //   console.log("Selected Marker Not Found");
      // }
    }
  }, [isDetailPerson, latitude, longitude, map, markerRefs]);

  return null;
}

function openPopupForLocation(latitude, longitude, markerRefs) {
  const selectedMarker = markerRefs.current.find(
    (marker) =>
      marker.getLatLng().lat === parseFloat(latitude) &&
      marker.getLatLng().lng === parseFloat(longitude)
  );
  if (selectedMarker) {
    selectedMarker.openPopup();
  } else {
    console.log("Selected Marker Not Found");
  }
}

export default function MapData(props) {
  const RecRef = React.createRef();
  const { event, styles, latitude, longitude, isDetailPerson, summary } = props;
  let isLogin = authService.isLogin();
  isLogin = isLogin && JSON.parse(isLogin);
  let defaultData = {
    name: "",
    typegeo: "",
    userId: isLogin.id,
    // assetId: selectedAssetId,
    // assetgeo: bindAsset,
    assetgeo: "", // harusnya dari yg select set bind asset
    radius: "",
    geoDraw: "",
    typeShape: "",
  };
  const markerRefs = useRef([]);
  const contextMenuRef = useRef(null);
  const [tab, setTab] = useState("general");
  const [editGeofence, setEditGeofence] = useState(false);
  const [formAssets, setFormAssets] = useState([]);
  const [typeShape, setTypeShape] = useState(null);
  const [latDraw, setLatDraw] = useState(null);
  const [longDraw, setLongDraw] = useState(null);
  const [geoDraw, setGeoDraw] = useState(null);
  const [radiusDraw, setRadiusDraw] = useState(null);
  const [curMarkerBos, setCurMarkerBos] = useState(null);
  const [showBreadcrumb, setShowBreadcrumb] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [typeDraw, setTypeDraw] = useState("marker");
  const [dataGeofence, setDataGeofence] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [payload, setPayload] = useState(defaultData);
  const [showModalGeo, setShowModalGeo] = useState(false);
  const editableLayers = new L.FeatureGroup();

  useEffect(() => {
    markerRefs.current.forEach((marker, index) => {
      if (marker) {
        marker.setRotationAngle(event[index].heading);
        marker.setRotationOrigin("center");
      }
    });
  }, [event]);

  useEffect(() => {
    if (event?.length > 0) {
      const assetList = event.map((item) => ({
        label: item.device?.device_name,
        value: item.device?.asset_id,
      }));
      setAssetList(assetList);
    }
  }, [event]);

  useEffect(() => {
    fetchGeoFence();
  }, []);

  useEffect(() => {
    if (geoDraw || radiusDraw || formAssets || typeShape) {
      let newData = {
        ...payload,
        radius: radiusDraw,
        geoDraw: geoDraw,
        assetgeo: formAssets,
        typeShape: typeShape,
      };
      setPayload(newData);
    }
  }, [geoDraw, radiusDraw, formAssets, typeShape]);

  const handleChange = (e) => {
    const newData = { ...payload };
    newData[e.target.name] = e.target.value;
    setPayload(newData);
  };

  const fetchGeoFence = () => {
    axios
      .get(`${GPS_URL}geofences`, {
        headers: {
          Authorization: `Bearer ${isLogin?.token}`,
        },
      })
      .then(function (response) {
        let clone = [...response.data.data];
        setDataGeofence(clone);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const _onCreated = (e) => {
    let type = e.layerType;
    setTypeShape(type);
    let layer = e.layer;
    if (type === "marker") {
      setLatDraw(layer.toGeoJSON().geometry.coordinates[1]);
      setLongDraw(layer.toGeoJSON().geometry.coordinates[0]);
      setCurMarkerBos(layer);
    } else {
      try {
        setTypeDraw("notmarker");
        if (layer._mRadius != null) {
          setRadiusDraw(layer.getRadius());
          setGeoDraw(layer.toGeoJSON());
          setCurMarkerBos(layer);
        } else if (layer._latlngs[0].length === 4) {
          const latlngs = layer._latlngs[0];
          setGeoDraw(latlngs.map(({ lat, lng }) => [lat, lng]));
          setCurMarkerBos(layer);
        } else {
          setGeoDraw(layer._latlngs[0].map(({ lat, lng }) => [lat, lng]));
          setCurMarkerBos(layer);
        }
      } catch (error) {
        console.log(error);
      }
    }
    editableLayers.addLayer(layer);
    editableLayers.on("click", onLayerClick);
  };

  const iconAsset = (color) => {
    return `<svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
          <polygon points="25,0 50,50 25,35 0,50" fill="${color}" />
    </svg>`;
  };

  const customIcon = (color) => {
    return new L.DivIcon({
      html: `<svg width="25" height="25" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
          <polygon points="25,0 50,50 25,35 0,50" fill="${color}" />
      </svg>`,
      iconSize: [3, 5], // Reduced size of the icon
      iconAnchor: [6, 20], // Adjusted anchor point
      popupAnchor: [1, -17], // Adjusted popup anchor point
      className: "", // Optional: add custom class if needed
    });
  };

  const activeTab = (tab) => {
    setTab(tab);
  };

  const handleShowBreadcrumb = (item) => {
    setShowBreadcrumb(!showBreadcrumb);
    if (showBreadcrumb) {
      setBreadcrumb([]);
    } else {
      getBreadcrumb(item.device_imei);
    }
  };

  const onLayerClick = () => {
    setShowModalGeo(true);
  };

  const getBreadcrumb = async (id) => {
    try {
      const response = await axios.get(
        `${GPS_URL}breadcrumb?deviceImei=${id}`,
        {
          headers: {
            Authorization: `Bearer ${isLogin?.token}`,
          },
        }
      );
      let breadcrumb = response.data.message.map((item) => ({
        lat: item.latitude,
        lng: item.longitude,
      }));
      setBreadcrumb(breadcrumb);
    } catch (error) {
      console.log(error);
    }
  };

  const loadOptions = async (inputValue) => {
    // Filter events based on the input value
    const filteredEvents = event.filter((item) =>
      item.device?.device_name.toLowerCase().includes(inputValue.toLowerCase())
    );

    // Convert filtered events to the format required by react-select
    return filteredEvents.map((item) => ({
      label: item.device?.device_name,
      value: item.device?.device_id,
    }));
  };

  const handleAssetChange = (selectedOptions) => {
    setFormAssets(selectedOptions);
    setPayload((prevPayload) => ({
      ...prevPayload,
      assetgeo: selectedOptions.map((option) => option.value),
    }));
  };

  const handleSubmitGeofence = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${GPS_URL}geofences`, payload, {
        headers: {
          Authorization: `Bearer ${isLogin?.token}`,
        },
      });
      setShowModalGeo(false);
      setPayload(defaultData);
      setFormAssets([]);
      setDataGeofence([...dataGeofence, response.data.message]);
    } catch (error) {
      console.log(error);
    }
    // console.log(payload);
  };

  const handleUpdateGeofence = async (e) => {
    e.preventDefault();
    let payloadData = {
      assetgeo: formAssets,
      name: payload.name,
      typegeo: payload.typegeo,
    };

    try {
      const response = await axios.put(
        `${GPS_URL}geofences/${payload.id}`,
        payloadData,
        {
          headers: {
            Authorization: `Bearer ${isLogin?.token}`,
          },
        }
      );
      let res = response.data.message;
      setDataGeofence((prevDataGeofence) => {
        return prevDataGeofence.map((item) =>
          item.id === res.id ? res : item
        );
      });
      // fetchGeoFence();
      setShowModalGeo(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditGeo = (item) => {
    // e.preventDefault();
    setEditGeofence(true);
    setShowModalGeo(true);
    let listAsset = item.geofence_bindings.map((v) => ({
      value: v.asset_id,
      label: v.asset?.asset_name,
    }));
    setFormAssets(listAsset);
    setPayload({
      ...payload,
      id: item.id,
      name: item.geofence_name,
      typegeo: item.type,
    });
  };

  const deleteGeofence = (id) => {
    axios
      .delete(`${GPS_URL}geofences/${id}`, {
        headers: {
          Authorization: `Bearer ${isLogin?.token}`,
        },
      })
      .then(function (response) {
        // console.log(response);
        let idGeofence = response.data.geofence_id;
        let clone = [...dataGeofence];
        let index = clone.findIndex((item) => item.id === idGeofence);
        clone.splice(index, 1);
        setDataGeofence(clone);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <MapContainer
      center={[-0.789275, 113.921327]}
      zoom={5}
      onContextMenu={(e) => console.log("tes")}
    >
      <FeatureGroup ref={contextMenuRef}>
        <EditControl
          position="topleft"
          draw={{
            polyline: false,
            rectangle: true,
            circlemarker: false,
            polygon: true,
            marker: true,
            circle: true,
          }}
          onCreated={_onCreated}
        />
      </FeatureGroup>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <FlyToLocation
        latitude={latitude}
        longitude={longitude}
        isDetailPerson={isDetailPerson}
        markerRefs={markerRefs}
      />
      {dataGeofence?.length > 0 &&
        dataGeofence.map((item, idx) =>
          item.type_geo == "circle" ? (
            item.geometry !== "null" ? (
              <Circle
                key={idx}
                center={[
                  JSON.parse(item.geometry).geometry.coordinates[1],
                  JSON.parse(item.geometry).geometry.coordinates[0],
                ]}
                radius={parseInt(item.radius)}
                fillColor={
                  item.type === "in"
                    ? "#f00"
                    : item.type === "out"
                    ? "#0f0"
                    : "#00f"
                }
              >
                <Popup>
                  <div className="mb-1">
                    <b>Type:</b> {item.type}
                  </div>
                  <div className="mb-1">
                    <b>Geofence Name:</b> {item.geofence_name}
                  </div>
                  <div className="mb-2">
                    <b>Asset:</b>{" "}
                    {item.geofence_bindings
                      ?.map((v) => v.asset?.asset_name)
                      .join(", ")}
                  </div>
                  <div className="mb-1 d-flex gap-2 justify-content-between">
                    <button
                      className="btn btn-primary w-50 py-0"
                      style={{ fontSize: "12px" }}
                      onClick={() => handleEditGeo(item)}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => deleteGeofence(item.id)}
                      className="btn btn-danger w-50"
                      style={{ fontSize: "12px" }}
                    >
                      Delete
                    </button>
                  </div>
                </Popup>
                )
              </Circle>
            ) : (
              ""
            )
          ) : item.type_geo == "rectangle" ? (
            item.geometry !== "null" ? (
              <Rectangle
                key={idx}
                bounds={JSON.parse(item.geometry)}
                ref={RecRef}
                fillColor={
                  item.type === "in"
                    ? "#f00"
                    : item.type === "out"
                    ? "#0f0"
                    : "#00f"
                }
              >
                <Popup>
                  <div className="mb-1">
                    <b>Type:</b> {item.type}
                  </div>
                  <div className="mb-1">
                    <b>Geofence Name:</b> {item.geofence_name}
                  </div>
                  <div className="mb-2">
                    <b>Asset:</b>{" "}
                    {item.geofence_bindings
                      ?.map((v) => v.asset?.asset_name)
                      .join(", ")}
                  </div>
                  <div className="mb-1 d-flex gap-2 justify-content-between">
                    <button
                      className="btn btn-primary w-50 py-0"
                      style={{ fontSize: "12px" }}
                      onClick={() => handleEditGeo(item)}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => deleteGeofence(item.id)}
                      className="btn btn-danger w-50"
                      style={{ fontSize: "12px" }}
                    >
                      Delete
                    </button>
                  </div>
                </Popup>
              </Rectangle>
            ) : (
              ""
            )
          ) : item.geometry !== "null" ? (
            <Polygon key={idx} positions={JSON.parse(item.geometry)}>
              <Popup>
                <div className="mb-1">
                  <b>Type:</b> {item.type}
                </div>
                <div className="mb-1">
                  <b>Geofence Name:</b> {item.geofence_name}
                </div>
                <div className="mb-2">
                  <b>Asset:</b>{" "}
                  {item.geofence_bindings
                    ?.map((v) => v.asset?.asset_name)
                    .join(", ")}
                </div>
                <div className="mb-1 d-flex gap-2 justify-content-between">
                  <button
                    className="btn btn-primary w-50 py-0"
                    style={{ fontSize: "12px" }}
                    onClick={() => handleEditGeo(item)}
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => deleteGeofence(item.id)}
                    className="btn btn-danger w-50"
                    style={{ fontSize: "12px" }}
                  >
                    Delete
                  </button>
                </div>
              </Popup>
            </Polygon>
          ) : (
            ""
          )
        )}

      {event?.length > 0 &&
        event?.map((item, index) => {
          // const formattedTimestamp = moment(
          //   item.timestamp_gps,

          //   "YYYY-MM-DD HH:mm:ss"
          // ).format("YYYY-MM-DD HH:mm:ss");
          // const fiveMinutesAgo = moment()
          //   .subtract(2400, "minutes")
          //   .format("YYYY-MM-DD HH:mm:ss");
          // const isWithinLastFiveMinutes =
          //   moment(formattedTimestamp).isAfter(fiveMinutesAgo);
          // console.log(moment(formattedTimestamp), fiveMinutesAgo);
          console.log(
            item?.device?.owner?.groups?.length,
            item?.device?.owner?.groups
          );
          return (
            <Marker
              key={index}
              position={[item.latitude, item.longitude]}
              icon={customIcon(
                item?.device?.owner?.groups?.length > 0
                  ? item.active
                    ? item.device?.owner?.groups[0]?.color
                    : "gray"
                  : "#000"
              )}
              eventHandlers={{
                click: () => activeTab("general"),
              }}
              ref={(el) => (markerRefs.current[index] = el)}
            >
              <Popup minWidth={230} maxWidth={230}>
                <div className={styles.photoWrap}>
                  {item.photo ? (
                    <img className="w-100" src={item.photo} alt="photo" />
                  ) : (
                    <div className={styles.photoNotFound}>Tidak Ada Foto</div>
                  )}
                </div>
                <div>
                  <div className="d-flex justify-content-between gap-2">
                    <div
                      onClick={() => activeTab("general")}
                      className={`${styles.tabGeneral} ${
                        tab === "general" ? styles.active : ""
                      }`}
                    >
                      General Info
                    </div>
                    <div
                      onClick={() => activeTab("device")}
                      className={`${styles.tabGeneral} ${
                        tab === "device" ? styles.active : ""
                      }`}
                    >
                      Device Info
                    </div>
                  </div>
                  {tab === "general" && (
                    <>
                      <div className="mb-1">
                        <b>Device Name:</b> {item.device?.device_name}
                      </div>
                      <div className="mb-1">
                        <b>Latitude:</b> {item.latitude}
                      </div>
                      <div className="mb-1">
                        <b>Longitude:</b> {item.longitude}
                      </div>
                      <div className="mb-1">
                        <b>Heading:</b> {item.heading}°
                      </div>
                      <div className="mb-1">
                        <b>Speed:</b> {item.velocity} KT
                      </div>
                      <div className="mb-3">
                        <b>Time Stamp:</b> {item.timestamp_gps}
                      </div>
                    </>
                  )}
                  {tab === "device" && (
                    <>
                      <div className="mb-1">
                        <b>Device Name:</b> {item.device?.device_name}
                      </div>
                      <div className="mb-1">
                        <b>Device IP:</b> {item.device?.device_ip}
                      </div>
                      <div className="mb-1">
                        <b>Device Type:</b> {item.device?.device_type}
                      </div>
                      <div className="mb-3">
                        <b>IMEI:</b> {item.device_imei}
                      </div>
                    </>
                  )}
                  <div className={styles.showBreadcrumb}>
                    <button
                      className={styles.showBreadcrumbButton}
                      onClick={() => handleShowBreadcrumb(item)}
                    >
                      {showBreadcrumb ? "Hide Breadcrumb" : "Show Breadcrumb"}
                    </button>
                  </div>
                </div>
              </Popup>
            </Marker>
          );
        })}

      {showBreadcrumb && (
        <>
          <Polyline
            positions={breadcrumb}
            color={"red"}
            weight={3}
            opacity={0.5}
            smoothFactor={1}
          />
          {breadcrumb?.length > 0 &&
            breadcrumb?.map((item, i) => {
              return (
                <CircleMarker key={i} center={item} radius={2}>
                  <Popup>
                    <table>
                      <tbody>
                        <tr>
                          <td>Latitude:</td>
                          <td>{item.lat}</td>
                        </tr>
                        <tr>
                          <td>Longitude:</td>
                          <td>{item.lng}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Popup>
                </CircleMarker>
              );
            })}
        </>
      )}

      <CommonModal
        title={editGeofence ? "Edit Geofence" : "Tambah Geofence"}
        show={showModalGeo}
        onHide={() => setShowModalGeo(false)}
      >
        <form
          onSubmit={editGeofence ? handleUpdateGeofence : handleSubmitGeofence}
        >
          <div className="mb-3">
            <label htmlFor="geofenceName" className="form-label">
              Geofence Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter geofence name"
              onChange={handleChange}
              name="name"
              value={payload.name}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="assetSelect" className="form-label">
              Select Asset
            </label>
            <AsyncSelect
              id="assign"
              loadOptions={loadOptions}
              isMulti
              defaultOptions={assetList}
              onChange={handleAssetChange}
              value={formAssets}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="geofenceType" className="form-label">
              Geofence Type
            </label>
            <select
              className="form-select"
              id="typegeo"
              name="typegeo"
              onChange={handleChange}
              value={payload.typegeo}
            >
              <option value="" hidden>
                Choose a type
              </option>
              <option value="in">Going Inside</option>
              <option value="out">Going Outside</option>
              <option value="both">Both Ways</option>
            </select>
          </div>
          <div className="w-100 text-end">
            <button type="submit" className="btn btn-primary">
              {editGeofence ? "Update Geofence" : "Save Geofence"}
            </button>
          </div>
        </form>
      </CommonModal>
    </MapContainer>
  );
}
