import { Button, Image } from "react-bootstrap";
import styles from "./../styles/Login.module.scss";
import email from "./../assets/Auth/email.svg";
import eye from "./../assets/Auth/eye.svg";
import password from "./../assets/Auth/password.svg";
import username from "./../assets/Auth/personalcard.svg";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../components/Layout/AuthLayout";
import { useEffect, useRef, useState } from "react";
import { AuthServices } from "../http/AuthHttp";

export default function Register() {
  const navigate = useNavigate();
  const passwordRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
    username: "",
    first_name: "",
    last_name: "",
    term: "1",
  });

  useEffect(() => {
    if (showPassword) {
      passwordRef.current.type = "text";
    } else {
      passwordRef.current.type = "password";
    }
  }, [showPassword]);

  useEffect(() => {
    if (localStorage.getItem("cakrawala-auth")) {
      navigate("/");
    }
  }, []);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const response = await AuthServices.register(data);
      if (response) {
        setIsLoading(false);
        let cookie = {
          token: response.token,
          id: response.user.id,
          name: response.user.first_name,
        };
        localStorage.setItem("cakrawala-auth", JSON.stringify(cookie));
        navigate("/");
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout isLoading={isLoading}>
      <div className={styles.circle}></div>
      <div className={styles.form}>
        <div className={styles.title}>Register</div>
        <div className={styles.text}>
          Registrasikan akun anda untuk memberli paket
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <label className={styles.text}>First Name</label>
            <div className={styles.inputWrap}>
              <Image src={username} alt="username" />
              <input
                onChange={handleChange}
                className={styles.inputBox}
                type="text"
                placeholder="First Name"
                id="first_name"
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.text}>Last Name</label>
            <div className={styles.inputWrap}>
              <Image src={username} alt="username" />
              <input
                onChange={handleChange}
                className={styles.inputBox}
                type="text"
                placeholder="Last Name"
                id="last_name"
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.text}>Email</label>
            <div className={styles.inputWrap}>
              <Image src={email} alt="email" />
              <input
                onChange={handleChange}
                className={styles.inputBox}
                type="email"
                placeholder="user@email.com"
                id="email"
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.text}>Password</label>
            <div className={styles.inputWrap}>
              <Image src={password} alt="password" />
              <input
                onChange={handleChange}
                className={`${styles.inputBox} ${styles.inputBox__password}`}
                type="password"
                id="password"
                ref={passwordRef}
                placeholder="Password Kamu"
              />
              <Image
                className={styles.password}
                onClick={handleShowPassword}
                src={eye}
                alt="eye"
              />
            </div>

            <Button variant="primary" type="submit">
              Daftar
            </Button>
          </div>
        </form>
        <div className={styles.sign}>
          Sudah punya akun?{" "}
          <Link to="/login">
            <b>Sign In</b>
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
}
