import axios from "axios";
import arrowLeft from "../../assets/arrow-left.svg";
import { CHAT_URL } from "../../constant/variable";
import { authService } from "../../constant/authServices";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

export default function ChatRoom(props) {
  const msgRef = useRef(null);
  const { styles, openChat, id, isOpenChat, messages, getMessage } = props;
  const [message, setMessage] = useState("");
  const isLogin = authService.isLogin();

  useEffect(() => {
    console.log(isOpenChat);
  }, [isOpenChat]);

  const sendMessage = async () => {
    msgRef.current.value = "";

    let parse = JSON.parse(isLogin);
    let userId = parse.message?.user.id;
    let payload = {
      id: id,
      message: message,
      type: "user",
    };
    try {
      const response = await axios.post(`${CHAT_URL}sendMessage`, payload, {
        headers: {
          Authorization: `Bearer ${parse.token}`,
        },
      });
      if (response.data?.status == 200) {
        getMessage(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const typingMsg = (e) => {
    setMessage(e.target.value);
  };

  const enterClick = (e) => {
    if (e.key === "Enter" || e.key === "13") {
      sendMessage();
    }
  };

  return (
    <div className={styles.chatRoom}>
      <div className={styles.chatRoomHeader}>
        <div className={styles.chatRoomTitle}>
          <button onClick={(e) => openChat(e, "")}>
            <span className={styles.chatRoomTitleIcon}>
              <img src={arrowLeft} />
            </span>
          </button>
          {messages?.to_user?.name}
        </div>
      </div>
      <div className={styles.chatRoomBody}>
        {messages?.messages ? (
          messages?.messages?.length > 0 ? (
            messages?.messages?.map((_, index) => (
              <>
                <div
                  className={`${
                    _.from_id == id
                      ? styles.chatRoomIncoming
                      : styles.chatRoomSent
                  }`}
                >
                  <div
                    className={`${
                      _.from_id == id
                        ? styles.chatRoomIncomingText
                        : styles.chatRoomSentText
                    }`}
                  >
                    <div className={styles.chatRoomIncomingTextMessage}>
                      {_.body}
                    </div>
                    <div className={styles.chatRoomIncomingTextTime}>
                      {moment(_.created_at).format("HH:mm")}
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div className="text-center">No messages</div>
          )
        ) : (
          <>
            <Skeleton count={6} height={50} style={{ marginBottom: 6 }} />
          </>
        )}
      </div>
      <div className={styles.chatRoomSent}>
        <input
          ref={msgRef}
          type="text"
          placeholder="Type a message"
          onChange={typingMsg}
          onKeyDown={enterClick}
        />
        <div>
          <button onClick={sendMessage}>Kirim</button>
        </div>
      </div>
    </div>
  );
}
