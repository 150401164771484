// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading_loadingWrap__aW-Uo {
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(169, 169, 169, 0.5);
  z-index: 999;
  position: fixed;
  font-weight: 600;
  width: 100%;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Loading.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,oCAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,MAAA;AACF","sourcesContent":[".loadingWrap {\n  height: 100vh;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: rgb(169 169 169 / 50%);\n  z-index: 999;\n  position: fixed;\n  font-weight: 600;\n  width: 100%;\n  top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingWrap": `Loading_loadingWrap__aW-Uo`
};
export default ___CSS_LOADER_EXPORT___;
